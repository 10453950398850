import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import emailjs from "emailjs-com";
import AsideSimulateur from "../../components/simulateur/AsideSimulateur";
import { Helmet } from "react-helmet";

const SimulateurFinal = () => {
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { references, source } = location.state || {
    references: 0,
    source: "",
  };

  const handleEmailChange = (e) => setEmail(e.target.value);

  //EmailJs
  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .send(
        process.env.REACT_APP_YOUR_SERVICE_ID,
        process.env.REACT_APP_YOUR_TEMPLATE_ID_TWO,
        {
          to_email: email,
          message: `Vous avez une nouvelle soumission de simulateur :
                - Source : ${source}
                - Références : ${references}`,
        },
        process.env.REACT_APP_YYOUR_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsSubmitted(true);
        },
        (error) => {
          console.log(error.text);
        }
      );

    setEmail("");
  };

  const closePopup = () => {
    setIsSubmitted(false);
    document.body.style.overflow = "auto";
  };

  return (
    <div className="simulateur">
      <Helmet>
        <title>Notre simulateur étape final</title>
        <meta
          name="description"
          content={"Parlons un peu de votre projet étape final"}
        />
        <meta property="og:title" content={"Notre simulateur étape final"} />
        <meta
          property="og:description"
          content={"Parlons un peu de votre projet étape final"}
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <AsideSimulateur page={6} />
      <div className="simulateur-content">
        <main className="main final-main">
          <div className="centered-content">
            <h1>Bonne nouvelle</h1>
            <p className="first-paragraph">
              Nous avons <span className="highlight">{references}</span>{" "}
              références correspondant à votre projet
            </p>
            <p className="second-paragraph">
              Afin d'estimer votre projet, un expert JPS Films vous contactera
              pour un devis gratuit
            </p>
            <form onSubmit={handleSubmit} className="email-form">
              <label htmlFor="email">Votre email</label>
              <input
                type="email"
                id="email"
                placeholder="john@doe.com"
                value={email}
                onChange={handleEmailChange}
                required
              />
              <input type="submit" value="ENVOYER" />
            </form>
          </div>
        </main>
      </div>

      {isSubmitted && (
        <div className="popup">
          <div className="popup-header">
            <span className="popup-close-form" onClick={closePopup}>
              &times;
            </span>
          </div>
          <div className="popup-content">
            <Link to="/">
              <img
                className="PopUpLogoPicture"
                src="/Logo blanc horizontal.webp"
                alt="logo-JPS"
              />
            </Link>
            <p>
              Votre demande a bien été envoyée. <br />
              Notre équipe reviendra vers vous dans les plus brefs délais.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SimulateurFinal;
