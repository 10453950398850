import React, { useEffect, useState } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import Header from "../components/commun/Header";
import Footer from "../components/commun/Footer";
import JPSFilms from "../components/commun/JPSFilms";
import QuestionProjet from "../components/commun/QuestionProjet";
import BandeauMedia from "../components/commun/BandeauMedia";
import CarrouselBlog from "../components/commun/CarrouselBlog";
import BoutonsDevisRdvWhatsapp from "../components/commun/BoutonsDevisRdvWhatsapp";
import Erreur from "../components/commun/Erreur";
import { Helmet } from "react-helmet";

const API_BASE_URL = "https://passionate-book-96c97196c7.strapiapp.com/api";

const GuidesReglementations = ({ customStyles }) => {
  const [mediaData, setMediaData] = useState(null);
  const [descriptifData, setDescriptifData] = useState(null);
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch fonction
  const fetchData = async (url) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      });
      return response.data.data;
    } catch (error) {
      setError("Une erreur est survenue lors du chargement des données.");
      console.error("Erreur lors de la récupération des données:", error);
      return null;
    }
  };

  // Fetch data
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const mediaData = await fetchData(
          `${API_BASE_URL}/guides-medias?populate=*`
        );
        setMediaData(mediaData?.[0] || null);

        const descriptifData = await fetchData(
          `${API_BASE_URL}/guides-descriptifs?populate=*`
        );
        setDescriptifData(descriptifData?.[0]?.attributes.Descriptif || null);

        const articlesData = await fetchData(
          `${API_BASE_URL}/articles?populate=*`
        );
        setArticles(articlesData || []);
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, []);

  // render strapi optionnal text
  const renderRichText = (descriptif) => {
    return descriptif.map((block, index) => {
      switch (block.type) {
        case "paragraph":
          return (
            <p key={index}>
              {block.children.map((child) => child.text).join("")}
            </p>
          );
        case "heading":
          const HeadingTag = `h${block.level}`;
          return (
            <HeadingTag key={index}>
              {block.children.map((child) => child.text).join("")}
            </HeadingTag>
          );
        case "list":
          return block.format === "unordered" ? (
            <ul key={index}>
              {block.children.map((item, i) => (
                <li key={i}>
                  {item.children.map((child) => child.text).join("")}
                </li>
              ))}
            </ul>
          ) : null;
        default:
          return null;
      }
    });
  };

  //fade in animation
  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1.5 } },
  };

  if (loading) return <div></div>;
  if (error)
    return (
      <div>
        <Erreur />
      </div>
    );
  if (!mediaData || !descriptifData)
    return (
      <div>
        <Erreur />
      </div>
    );

  const bandeauData = {
    Titre: mediaData.attributes.Titre || "",
    Introduction: mediaData.attributes.Introduction || "",
    Media: {
      data: {
        attributes: {
          url: mediaData.attributes.Media.data.attributes.url || "",
          ext: mediaData.attributes.Media.data.attributes.ext || "",
          alternativeText:
            mediaData.attributes.Media.data.attributes.alternativeText || "",
        },
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>{bandeauData.Titre || "Guides & réglementations"}</title>
        <meta
          name="description"
          content={
            bandeauData.Introduction ||
            '"Nos films répondent aux normes européennes de qualité ISO 9001/9002 et au plan PPMS."'
          }
        />
        <meta
          property="og:title"
          content={bandeauData.Titre || "Guides & réglementations"}
        />
        <meta
          property="og:description"
          content={
            bandeauData.Introduction ||
            '"Nos films répondent aux normes européennes de qualité ISO 9001/9002 et au plan PPMS."'
          }
        />
        <meta
          property="og:image"
          content={
            bandeauData.Media.data.attributes.url ||
            `${window.location.origin}/logo.webp`
          }
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <Header />
      <div className="guides-reglementations">
        <div className="guides-reglementations__bandeau">
          <BandeauMedia data={bandeauData} hideTitle={true} />
          <motion.div
            className="guides-reglementations__overlay"
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
          >
            <h2 className="guides-reglementations__title">
              {bandeauData.Titre}
            </h2>
            <p className="guides-reglementations__introduction">
              {bandeauData.Introduction}
            </p>
          </motion.div>
          <BoutonsDevisRdvWhatsapp
            customStyles={customStyles?.floatingButtons}
          />
        </div>
        <div className="guides-reglementations__content">
          {renderRichText(descriptifData)}
        </div>
        <JPSFilms />
        <CarrouselBlog articles={articles} />
        <QuestionProjet />
        <Footer />
      </div>
    </>
  );
};

export default GuidesReglementations;
