import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Importez useNavigate pour la navigation
import { motion } from "framer-motion";
import BoutonsDevisRdvWhatsapp from "../commun/BoutonsDevisRdvWhatsapp";

//First Banner in screen used in /Accueil , /template-section , /nos-services ,  / qui-sommes-nous  pages

const BandeauDescriptifUn = ({
  data,
  customStyles,
  primaryButtonLink,
  secondaryButtonLink,
}) => {
  const {
    Introduction = "",
    Titre = "",
    Descriptif = [],
    PrincipalTexteUn = "",
    PrincipalTexteDeux = "",
    PrincipalListe = [],
    Bouton = "",
    BoutonDeux = "",
    Media,
    Logos = [],
  } = data;
  const imageUrl =
    Media?.data?.attributes?.url || Media?.data?.[0]?.attributes?.url || "";
  const logos = Logos?.data || [];
  const navigate = useNavigate();

  useEffect(() => {
    if (imageUrl) {
      // Précharger l'image
      const link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = imageUrl;
      document.head.appendChild(link);
    }
  }, [imageUrl]);

  //fade in animation
  const slideFromBottom = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  useEffect(() => {
    if (imageUrl) {
      const img = new Image();
      img.src = imageUrl;
    }
  }, [imageUrl]);

  return (
    <div className="bandeau-descriptif-un" style={customStyles?.container}>
      <div className="text-section-bandUn" style={customStyles?.textSection}>
        <motion.div
          className="text-section"
          style={customStyles?.textSection}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.8 }}
          variants={slideFromBottom}
        >
          {Introduction && (
            <h1 className="introduction" style={customStyles?.introduction}>
              {Introduction.toUpperCase()}
            </h1>
          )}
          {Titre && (
            <p className="titre" style={customStyles?.title}>
              {Titre}
            </p>
          )}
          {PrincipalTexteUn && (
            <p style={customStyles?.text}>{PrincipalTexteUn}</p>
          )}
          {Array.isArray(Descriptif) && Descriptif.length > 0 && (
            <ul className="descriptif" style={customStyles?.list}>
              {Descriptif.map((item, index) => (
                <li
                  className="descriptif-liste"
                  key={index}
                  style={customStyles?.listItem}
                >
                  {item.children[0]?.text || ""}
                </li>
              ))}
            </ul>
          )}
          {Array.isArray(PrincipalListe) && PrincipalListe.length > 0 && (
            <ul className="liste" style={customStyles?.list}>
              {PrincipalListe.map((item, index) => (
                <li key={index} style={customStyles?.listItem}>
                  <i className="fas fa-check"></i>{" "}
                  {item.children[0]?.text || ""}
                </li>
              ))}
            </ul>
          )}
          {PrincipalTexteDeux && (
            <p style={customStyles?.text}>{PrincipalTexteDeux}</p>
          )}
          <div className="buttons">
            {Bouton && (
              <button
                className="btn-primary"
                style={customStyles?.button}
                onClick={() => navigate(primaryButtonLink)}
              >
                {Bouton.toUpperCase()}
              </button>
            )}
            {BoutonDeux && (
              <button
                className="btn-secondary"
                style={customStyles?.button}
                onClick={() => navigate(secondaryButtonLink)}
              >
                {BoutonDeux.toUpperCase()}
              </button>
            )}
          </div>
        </motion.div>
      </div>
      <div className="image-section" style={customStyles?.imageSection}>
        <div className="image-container" style={customStyles?.imageContainer}>
          {imageUrl && (
            <div
              className="bandeau-image"
              style={{
                ...customStyles?.bandeauImage,
                backgroundImage: `url(${imageUrl})`,
              }}
            ></div>
          )}
          <motion.div
            className="text-section"
            style={customStyles?.textSection}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.8 }}
            variants={slideFromBottom}
          >
            {logos && logos.length > 0 ? (
              <div className="logos">
                {logos.map((logo) => (
                  <img
                    key={logo.id}
                    src={logo.attributes.url}
                    alt={logo.attributes.name}
                    style={customStyles?.logo}
                  />
                ))}
              </div>
            ) : (
              <div className="no-logos"></div>
            )}
          </motion.div>
          <BoutonsDevisRdvWhatsapp />
        </div>
      </div>
    </div>
  );
};

export default BandeauDescriptifUn;
