import React from "react";
import { useNavigate } from "react-router-dom";
import AsideSimulateur from "../../components/simulateur/AsideSimulateur";
import { Helmet } from "react-helmet";

const SimulateurOccultantQuatre = () => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate("/simulateur/final", {
      state: { references: 6, source: "occultant" },
    });
  };

  return (
    <div className="simulateur">
      <Helmet>
        <title>Notre simulateur étape 4 occultant</title>
        <meta
          name="description"
          content={"Parlons un peu de votre projet étape 4 occultant"}
        />
        <meta
          property="og:title"
          content={"Notre simulateur étape 4 occultant"}
        />
        <meta
          property="og:description"
          content={"Parlons un peu de votre projet étape 4 occultant"}
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <AsideSimulateur page={4} />
      <div className="simulateur-content">
        <main className="main">
          <h1>Quel style aimeriez-vous ?</h1>
          <div className="card-grid">
            <div className="card" onClick={handleCardClick}>
              <img
                src="/assets/images/Simulateur/Occultant51.png"
                alt="Dépoli"
              />
              <p>Dépoli</p>
            </div>
            <div className="card" onClick={handleCardClick}>
              <img
                src="/assets/images/Simulateur/Occultant52.png"
                alt="Opaque"
              />
              <p>Opaque</p>
            </div>
            <div className="card" onClick={handleCardClick}>
              <img
                src="/assets/images/Simulateur/Occultant53.png"
                alt="Miroir"
              />
              <p>Miroir</p>
            </div>
            <div className="card" onClick={handleCardClick}>
              <img
                src="/assets/images/Simulateur/Occultant54.png"
                alt="Motif"
              />
              <p>Motif</p>
            </div>
            <div className="card" onClick={handleCardClick}>
              <img
                src="/assets/images/Simulateur/Occultant55.png"
                alt="Bande"
              />
              <p>Bande</p>
            </div>
            <div className="card" onClick={handleCardClick}>
              <img
                src="/assets/images/Simulateur/Occultant56.png"
                alt="Dégradé"
              />
              <p>Dégradé</p>
            </div>
            <div className="card" onClick={handleCardClick}>
              <img
                src="/assets/images/Simulateur/Occultant57.png"
                alt="Personnalisé"
              />
              <p>Personnalisé</p>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default SimulateurOccultantQuatre;
