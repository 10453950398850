import React from "react";
import { useNavigate } from "react-router-dom";
import RectangleGrid from "../../components/simulateur/RectangleGrid";
import AsideSimulateur from "../../components/simulateur/AsideSimulateur";
import { Helmet } from "react-helmet";

const SimulateurUn = () => {
  const navigate = useNavigate();

  const items = [
    "Entreprise",
    "Collectivité",
    "Indépendant",
    "Particulier",
    "Revendeur",
  ];

  const handleRectangleClick = (item) => {
    navigate("/simulateur/deux");
  };

  return (
    <div className="simulateur">
      <Helmet>
        <title>Notre simulateur</title>
        <meta name="description" content={"Parlons un peu de votre projet"} />
        <meta property="og:title" content={"Notre simulateur"} />
        <meta
          property="og:description"
          content={"Parlons un peu de votre projet"}
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <AsideSimulateur page={1} showBackButton={false} />
      <div className="simulateur-content">
        <main className="main">
          <h1>Vous êtes...</h1>
          <RectangleGrid items={items} onClick={handleRectangleClick} />
        </main>
      </div>
    </div>
  );
};

export default SimulateurUn;
