import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import BandeauDescriptifUn from "../components/commun/BandeauDescriptifUn";
import Header from "../components/commun/Header";
import BandeauMedia from "../components/commun/BandeauMedia";
import Domaines from "../components/commun/Domaines";
import SectionDescriptifs from "../components/templateSection/SectionDescriptifs";
import JPSFilms from "../components/commun/JPSFilms";
import QuestionProjet from "../components/commun/QuestionProjet";
import Footer from "../components/commun/Footer";
import CarrouselBlog from "../components/commun/CarrouselBlog";
import SousSectionsCartes from "../components/commun/SousSectionsCartes";
import Erreur from "../components/commun/Erreur";
import { Helmet } from "react-helmet";

const API_BASE_URL = "https://passionate-book-96c97196c7.strapiapp.com/api";

const TemplateSection = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [sectionData, setSectionData] = useState(null);
  const [media, setMedia] = useState(null);
  const [domaines, setDomaines] = useState([]);
  const [sectionDescriptifs, setSectionDescriptifs] = useState(null);
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  //fetch fonction
  const fetchData = async (url) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      });
      return response.data.data;
    } catch (error) {
      setError("Une erreur est survenue lors du chargement des données.");
      console.error("Erreur lors de la récupération des données:", error);
      return null;
    }
  };

  //fetch data
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const bandeauData = await fetchData(
          `${API_BASE_URL}/template-section-bandeau-description-uns/${id}?populate=*`
        );
        setData(bandeauData?.attributes || null);

        const sectionData = await fetchData(
          `${API_BASE_URL}/sections/${id}?populate=*`
        );
        setSectionData(sectionData?.attributes || null);

        const mediaData = await fetchData(
          `${API_BASE_URL}/template-section-medias?filters[sectionId][$eq]=${id}&populate=*`
        );
        const mediaAttributes = mediaData?.[0]?.attributes || null;
        const vignetteUrl =
          mediaAttributes?.Vignette?.data?.[0]?.attributes?.url || null;

        setMedia({
          ...mediaAttributes,
          vignetteUrl,
        });

        const domainesData = await fetchData(
          `${API_BASE_URL}/template-section-domaines?filters[sectionId][$eq]=${id}&populate=*`
        );
        setDomaines(domainesData || []);

        const descriptifsData = await fetchData(
          `${API_BASE_URL}/template-section-descriptifs/${id}?populate=*`
        );
        setSectionDescriptifs(descriptifsData?.attributes || null);

        const articlesData = await fetchData(
          `${API_BASE_URL}/articles?filters[sectionId][$eq]=${id}&populate=*`
        );
        setArticles(articlesData || []);
      } catch (error) {
        setError("Erreur lors de la récupération des données");
        console.error("Erreur lors de la récupération des données:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, [id]);

  if (loading) return <div></div>;
  if (error)
    return (
      <div>
        <Erreur />
      </div>
    );

  return (
    <>
      <Helmet>
        <title>{data?.Introduction || "Titre de la section"}</title>
        <meta
          name="description"
          content={data?.Titre || "Description de la section"}
        />
        <meta
          property="og:title"
          content={data?.Introduction || "Titre de la section"}
        />
        <meta
          property="og:description"
          content={data?.Titre || "Description de la section"}
        />
        <meta
          property="og:image"
          content={
            data?.Media?.data?.attributes?.url ||
            `${window.location.origin}/logo.webp`
          }
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <Header />
      <section className="bandeau-un">
        {data && (
          <BandeauDescriptifUn data={data} primaryButtonLink="/contact" />
        )}
      </section>
      <section className="section-info">
        <h2 className="titre-template-section">
          {sectionData?.TitreTemplateSection}
        </h2>
        <p className="descriptif-template-section">
          {sectionData?.DescriptifTemplateSection}
        </p>
        {articles.length > 0 && <SousSectionsCartes articles={articles} />}
      </section>
      {media && <BandeauMedia data={media} />}
      {domaines.length > 0 && (
        <Domaines
          domainesData={domaines}
          hideTitle={true}
          hideBorder={true}
          hideIcons={true}
          isTemplateSection={true}
        />
      )}
      {sectionDescriptifs && <SectionDescriptifs data={sectionDescriptifs} />}
      <JPSFilms />
      <CarrouselBlog sectionId={id} />
      <QuestionProjet />
      <Footer />
    </>
  );
};

export default TemplateSection;
