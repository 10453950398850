import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import FAQItem from "../components/commun/FAQItem";
import Header from "../components/commun/Header";
import axios from "axios";
import JPSFilms from "../components/commun/JPSFilms";
import CarrouselBlog from "../components/commun/CarrouselBlog";
import QuestionProjet from "../components/commun/QuestionProjet";
import Footer from "../components/commun/Footer";
import BandeauMedia from "../components/commun/BandeauMedia";
import BoutonsDevisRdvWhatsapp from "../components/commun/BoutonsDevisRdvWhatsapp";
import Erreur from "../components/commun/Erreur";
import { Helmet } from "react-helmet";

const API_BASE_URL = "https://passionate-book-96c97196c7.strapiapp.com/api";

const FAQPage = ({ customStyles }) => {
  const [articles, setArticles] = useState([]);
  const [mediaData, setMediaData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch fonction
  const fetchData = async (url) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      });
      return response.data.data;
    } catch (error) {
      setError("Une erreur est survenue lors du chargement des données.");
      console.error("Erreur lors de la récupération des données:", error);
      return null;
    }
  };

  //Fetch data
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const mediaData = await fetchData(
          `${API_BASE_URL}/faq-medias?populate=*`
        );
        setMediaData(mediaData?.[0] || null);

        const articlesData = await fetchData(
          `${API_BASE_URL}/articles?populate=*`
        );
        setArticles(articlesData || []);
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, []);

  //Fade in animation
  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1.5 } },
  };

  const bandeauData = {
    Titre: mediaData?.attributes?.Titre || "",
    Introduction: mediaData?.attributes?.Introduction || "",
    Media: {
      data: {
        attributes: {
          url: mediaData?.attributes?.Media?.data?.attributes?.url || "",
          ext: mediaData?.attributes?.Media?.data?.attributes?.ext || "",
          alternativeText:
            mediaData?.attributes?.Media?.data?.attributes?.alternativeText ||
            "",
        },
      },
    },
  };

  if (loading) return <div></div>;
  if (error)
    return (
      <div>
        <Erreur />
      </div>
    );
  if (!mediaData)
    return (
      <div>
        <Erreur />
      </div>
    );

  return (
    <>
      <Helmet>
        <title>Questions fréquentes</title>
        <meta
          name="description"
          content={
            "Voici les interrogations les plus fréquentes de nos clients."
          }
        />
        <meta property="og:title" content={"Questions fréquentes"} />
        <meta
          property="og:description"
          content={
            "Voici les interrogations les plus fréquentes de nos clients."
          }
        />
        <meta
          property="og:image"
          content={
            bandeauData.Media.data.attributes.url ||
            `${window.location.origin}/logo.webp`
          }
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <Header />
      <div className="faq-page">
        <div className="faq-page__bandeau">
          <BandeauMedia data={bandeauData} hideTitle={true} />
          <motion.div
            className="faq-page__overlay"
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
          >
            <h2 className="faq-page__title">{bandeauData.Titre}</h2>
            <p className="faq-page__introduction">{bandeauData.Introduction}</p>
          </motion.div>
          <BoutonsDevisRdvWhatsapp
            customStyles={customStyles?.floatingButtons}
          />
        </div>
        <FAQItem />
        <JPSFilms />
        <CarrouselBlog articles={articles} />
        <QuestionProjet />
        <Footer />
      </div>
    </>
  );
};

export default FAQPage;
