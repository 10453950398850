import React from "react";
import { useNavigate } from "react-router-dom";
import RectangleGrid from "../../components/simulateur/RectangleGrid";
import AsideSimulateur from "../../components/simulateur/AsideSimulateur";
import { Helmet } from "react-helmet";

const SimulateurTrois = () => {
  const navigate = useNavigate();

  const items = [
    "Température intérieure excessive",
    "Coût élevé de la climatisation",
    "Décoloration du mobilier",
    "Éblouissement du soleil",
    "Risque d’intrusion",
    "Dégradation volontaire",
    "Absence de confidentialité et d’intimité",
    "Regards indiscrets",
    "Risque de collision Absence de repères",
    "Manque d’esthétisme",
    "Absence d’identité visuelle",
    "Anti-explosion",
  ];

  const handleRectangleClick = (item) => {
    if (
      [
        "Température intérieure excessive",
        "Coût élevé de la climatisation",
        "Décoloration du mobilier",
        "Éblouissement du soleil",
      ].includes(item)
    ) {
      navigate("/simulateur/solaire-quatre");
    } else if (
      [
        "Risque d’intrusion",
        "Dégradation volontaire",
        "Absence de confidentialité et d’intimité",
        "Regards indiscrets",
      ].includes(item)
    ) {
      navigate("/simulateur/securite-quatre");
    } else if (
      [
        "Risque de collision Absence de repères",
        "Manque d’esthétisme",
        "Absence d’identité visuelle",
        "Anti-explosion",
      ].includes(item)
    ) {
      navigate("/simulateur/occultant-quatre");
    }
  };

  return (
    <div className="simulateur">
      <Helmet>
        <title>Notre simulateur étape 3</title>
        <meta
          name="description"
          content={"Parlons un peu de votre projet étape 3"}
        />
        <meta property="og:title" content={"Notre simulateur étape 3"} />
        <meta
          property="og:description"
          content={"Parlons un peu de votre projet étape 3"}
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <AsideSimulateur page={3} />
      <div className="simulateur-content">
        <main className="main">
          <h1>Quelle est votre problématique principale ?</h1>
          <RectangleGrid items={items} onClick={handleRectangleClick} />
        </main>
      </div>
    </div>
  );
};

export default SimulateurTrois;
