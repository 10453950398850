import React from "react";
import { useNavigate } from "react-router-dom";
import AsideSimulateur from "../../components/simulateur/AsideSimulateur";
import { Helmet } from "react-helmet";

const SimulateurSolaireCinq = () => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate("/simulateur/final", {
      state: { references: 5, source: "solaire" },
    });
  };

  return (
    <div className="simulateur">
      <Helmet>
        <title>Notre simulateur étape 5 solaire</title>
        <meta
          name="description"
          content={"Parlons un peu de votre projet étape 5 solaire"}
        />
        <meta
          property="og:title"
          content={"Notre simulateur étape 5 solaire"}
        />
        <meta
          property="og:description"
          content={"Parlons un peu de votre projet étape 5 solaire"}
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <AsideSimulateur page={5} />
      <div className="simulateur-content">
        <main className="main">
          <h1>Quel style aimeriez-vous ?</h1>
          <div className="card-grid">
            <div className="card" onClick={handleCardClick}>
              <img src="/assets/images/Simulateur/Solaire51.png" alt="Neutre" />
              <p>Neutre</p>
            </div>
            <div className="card" onClick={handleCardClick}>
              <img src="/assets/images/Simulateur/Solaire52.png" alt="Teinté" />
              <p>Teinté</p>
            </div>
            <div className="card" onClick={handleCardClick}>
              <img src="/assets/images/Simulateur/Solaire53.png" alt="Miroir" />
              <p>Miroir</p>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default SimulateurSolaireCinq;
