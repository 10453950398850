import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import Erreur from "./Erreur";

const API_BASE_URL = "https://passionate-book-96c97196c7.strapiapp.com/api";

const Footer = () => {
  const [sections, setSections] = useState([]);
  const [articlesBySection, setArticlesBySection] = useState({});
  const [error, setError] = useState(null);
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  // fetch fonction
  const fetchData = async (url) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      });
      return response.data.data;
    } catch (error) {
      setError("Erreur lors de la récupération des données");
      console.error("Erreur lors de la récupération des données:", error);
      return null;
    }
  };

  // fetch data : sections
  useEffect(() => {
    const fetchSections = async () => {
      const sectionsResponse = await fetchData(`${API_BASE_URL}/sections`);
      if (sectionsResponse) {
        setSections(sectionsResponse);
      }
    };
    fetchSections();
  }, []);

  // fetch data : articles (submenu) by sections
  useEffect(() => {
    const fetchArticles = async () => {
      if (sections.length > 0) {
        const articlesResponse = await fetchData(`${API_BASE_URL}/articles`);
        if (articlesResponse) {
          const articlesGroupedBySection = articlesResponse.reduce(
            (acc, article) => {
              const sectionId = article.attributes.sectionId;
              if (!acc[sectionId]) {
                acc[sectionId] = [];
              }
              acc[sectionId].push(article);
              return acc;
            },
            {}
          );

          setArticlesBySection(articlesGroupedBySection);
        }
      }
    };

    fetchArticles();
  }, [sections]);

  //fade in animation
  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1, threshold: 0.3 } },
  };

  if (error)
    return (
      <div>
        <Erreur />
      </div>
    );

  return (
    <footer>
      {/* First row (white background) */}
      <motion.div
        className="info-band"
        ref={ref}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        variants={fadeInVariants}
      >
        <div className="info-item">
          <img
            src={`${process.env.PUBLIC_URL}/assets/Icons footer/garantie.svg`}
            alt="Garantie"
          />
          <p>
            Jusqu'à <strong>10 ans</strong> de garantie
          </p>
        </div>
        <div className="info-item">
          <img
            src={`${process.env.PUBLIC_URL}/assets/Icons footer/economie.svg`}
            alt="Économie d'énergie"
          />
          <p>
            + <strong>410 Watts</strong> économisés par m²/h
          </p>
        </div>
        <div className="info-item">
          <img
            src={`${process.env.PUBLIC_URL}/assets/Icons footer/rouleau.svg`}
            alt="Références de films"
          />
          <p>
            + de <strong>150</strong> références de films
          </p>
        </div>
        <div className="info-item">
          <img
            src={`${process.env.PUBLIC_URL}/assets/Icons footer/Fenetre.svg`}
            alt="Surface de films posés"
          />
          <p>
            + <strong>15 000</strong> m² de films posés/an
          </p>
        </div>
      </motion.div>

      {/* Second row (dark blue) */}
      <div className="top-band">
        <a
          href={`${process.env.PUBLIC_URL}/assets/images/Brochure JPS_PageDigitale.pdf`}
          download="Brochure JPS_PageDigitale.pdf"
        >
          TÉLÉCHARGER NOTRE BROCHURE
        </a>
        <Link to="/guides-reglementations">GUIDES & RÉGLEMENTATIONS</Link>
        <Link to="/faq">QUESTIONS FRÉQUENTES</Link>
      </div>

      {/* Third row (main) */}
      <div className="main-footer">
        <div className="footer-row-large">
          <div className="footer-column-logo">
            <Link to="/" aria-label="Homepage">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/Logo-JPS-2.svg`}
                alt="Logo de l'entreprise"
              />
            </Link>
          </div>
          {sections.length > 0 ? (
            sections
              .sort((a, b) => a.id - b.id)
              .map((section) => (
                <div className="footer-column" key={section.id}>
                  <ul>
                    <li className="headerFooter">
                      <Link
                        to={`/section/${section.id}`}
                        title={section.attributes.title}
                      >
                        {section.attributes.title}
                      </Link>
                    </li>
                    {articlesBySection[section.id] &&
                    articlesBySection[section.id].length > 0 ? (
                      articlesBySection[section.id].map((article) => (
                        <li className="liste" key={article.id}>
                          <Link
                            to={`/template-sous-section/${article.id}`}
                            title={article.attributes.title}
                          >
                            {article.attributes.title || "Titre non disponible"}
                          </Link>
                        </li>
                      ))
                    ) : (
                      <li>Aucun article disponible</li>
                    )}
                  </ul>
                </div>
              ))
          ) : (
            <div>Aucune section disponible</div>
          )}
          <div className="footer-column-last">
            <ul>
              <li className="header-last">
                <Link to="/qui-sommes-nous">Nous découvrir</Link>
              </li>
              <li className="liste-last">
                <Link to="/qui-sommes-nous">A propos de JPS Films</Link>
              </li>
              <li className="liste-last">
                <Link to="/nos-services">Nos services</Link>
              </li>
              <li className="liste-last">
                <Link to="/nos réalisations">Nos réalisations</Link>
              </li>
              <li className="liste-last">
                <Link to="/nos-articles">Actualités</Link>
              </li>
            </ul>
          </div>
          <div className="footer-column-logo-two">
            <Link aria-label="Homepage">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/JPS-40-ans-expertise.png`}
                alt="JPS 40 ans d'expertise"
              />
            </Link>
          </div>
        </div>
        <div className="footer-separator"></div>

        {/* Fourth row */}
        <div className="footer-row-links">
          <div className="footer-follow">
            <Link className="follow">NOUS SUIVRE</Link>
            <div className="footer-social-grid">
              <a
                href="https://www.facebook.com/profile.php?id=100076091021280"
                className="social-link"
                aria-label="Facebook"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/Icons footer/social network/Facebook.svg`}
                  alt="lien vers Facebook"
                />{" "}
                Facebook
              </a>
              <a
                href="https://www.instagram.com/jpsfilmsfrance/?hl=fr"
                className="social-link"
                aria-label="Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/Icons footer/social network/Instagram.svg`}
                  alt="lien vers Instagram"
                />{" "}
                Instagram
              </a>
              <a
                href="https://www.linkedin.com/company/jps-films"
                className="social-link"
                aria-label="LinkedIn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/Icons footer/social network/Linkedin.svg`}
                  alt="lien vers LinkedIn"
                />{" "}
                LinkedIn
              </a>
              <a
                href="https://www.pinterest.fr/JPSFilmsFrance/"
                className="social-link"
                aria-label="Pinterest"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/Icons footer/social network/Pinterest.svg`}
                  alt="lien vers Pinterest"
                />{" "}
                Pinterest
              </a>
              {/* <a href="https://youtube.com" className="social-link" aria-label="YouTube">
                <img src={`${process.env.PUBLIC_URL}/assets/Icons footer/social network/Youtube.svg`} alt="lien vers YouTube" /> YouTube
              </a> */}
            </div>
          </div>
          <div className="footer-contact">
            <Link>NOUS CONTACTER</Link>
            <Link to="/contact">Accéder au formulaire</Link>
          </div>
        </div>
        <div className="footer-separator-two"></div>

        {/* Last row */}
        <div className="footer-row-small">
          <Link to="/plan-du-site">Plan du site</Link>
          <Link to="/mentions-legales">Mentions légales</Link>
          <Link to="/politique-de-confidentialite">
            Politique de confidentialité
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
