import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../components/commun/Header";
import Footer from "../components/commun/Footer";
import JPSFilms from "../components/commun/JPSFilms";
import QuestionProjet from "../components/commun/QuestionProjet";
import Domaines from "../components/commun/Domaines";
import LogosPartenaires from "../components/accueil/LogosPartenaires";
import BandeauDescriptifUn from "../components/commun/BandeauDescriptifUn";
import BandeauDescriptif from "../components/commun/BandeauDescriptif";
import BandeauMedia from "../components/commun/BandeauMedia";
import CarrouselBlog from "../components/commun/CarrouselBlog";
import Erreur from "../components/commun/Erreur";
import { Helmet } from "react-helmet";

const API_BASE_URL = "https://passionate-book-96c97196c7.strapiapp.com/api";

const QuiSommesNous = () => {
  const [bandeauDescriptifUnData, setBandeauDescriptifUnData] = useState(null);
  const [bandeauDescriptifData, setBandeauDescriptifData] = useState([]);
  const [bandeauMediaData, setBandeauMediaData] = useState(null);
  const [domainesData, setDomainesData] = useState([]);
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  //fetch fonction
  const fetchData = async (url) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      });
      return response.data.data;
    } catch (error) {
      setError("Une erreur est survenue lors du chargement des données.");
      console.error("Erreur lors de la récupération des données:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const bandeauDescriptifUnResponse = await fetchData(
          `${API_BASE_URL}/qui-sommes-nous-bandeau-descriptif-uns?populate=*`
        );
        setBandeauDescriptifUnData(
          bandeauDescriptifUnResponse?.[0]?.attributes || {}
        );

        const bandeauDescriptifResponse = await fetchData(
          `${API_BASE_URL}/qui-sommes-nous?populate=*`
        );
        setBandeauDescriptifData(
          bandeauDescriptifResponse?.map((item) => item.attributes) || []
        );

        const bandeauMediaResponse = await fetchData(
          `${API_BASE_URL}/accueil-medias?populate=*`
        );
        setBandeauMediaData(
          bandeauMediaResponse?.find((item) => item.id === 1)?.attributes ||
            null
        );

        const domainesResponse = await fetchData(
          `${API_BASE_URL}/accueil-domaines?populate=*`
        );
        setDomainesData(domainesResponse || []);

        const articlesResponse = await fetchData(
          `${API_BASE_URL}/articles?populate=*`
        );
        setArticles(articlesResponse || []);
      } catch (error) {
        setError("Une erreur est survenue lors du chargement des données.");
        console.error("Erreur lors de la récupération des données:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, []);

  if (loading) return <div></div>;
  if (error)
    return (
      <div>
        <Erreur />
      </div>
    );

  return (
    <div className="qui-sommes-nous-page">
      <Helmet>
        <title>Qui sommes nous ?</title>
        <meta
          name="description"
          content={"Une équipe en forme et bien formée"}
        />
        <meta property="og:title" content={"Qui sommes-nous"} />
        <meta
          property="og:description"
          content={"Une équipe en forme et bien formée"}
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <Header />
      {bandeauDescriptifUnData && (
        <BandeauDescriptifUn
          data={bandeauDescriptifUnData}
          primaryButtonLink="/nos-realisations"
          secondaryButtonLink="/contact"
        />
      )}
      {bandeauDescriptifData.map((item, index) => (
        <BandeauDescriptif
          key={item.id}
          data={item}
          reverse={index % 2 !== 0} // Alternating image positions
          primaryButtonLink={index === 0 ? "/nos-services" : "/contact"}
          className={index === 0 ? "index-1" : index === 1 ? "index-2" : ""}
        />
      ))}
      <Domaines domainesData={domainesData} />
      <LogosPartenaires />
      {bandeauMediaData && <BandeauMedia data={bandeauMediaData} />}
      <JPSFilms />
      <CarrouselBlog articles={articles} />
      <QuestionProjet />
      <Footer />
    </div>
  );
};

export default QuiSommesNous;
