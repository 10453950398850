import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import BandeauMedia from "../components/commun/BandeauMedia";
import Header from "../components/commun/Header";
import Form from "../components/commun/Form";
import SousSectionsCartes from "../components/commun/SousSectionsCartes";
import QuestionProjet from "../components/commun/QuestionProjet";
import Footer from "../components/commun/Footer";
import JPSFilms from "../components/commun/JPSFilms";
import FAQItem from "../components/commun/FAQItem";
import CarrouselBlog from "../components/commun/CarrouselBlog";
import BoutonsDevisRdvWhatsapp from "../components/commun/BoutonsDevisRdvWhatsapp";
import SousSectionDescriptifs from "../components/templateSousSection/SousSectionDescriptifs";
import Erreur from "../components/commun/Erreur";

const API_BASE_URL = "https://passionate-book-96c97196c7.strapiapp.com/api";

const TemplateSousSection = ({ customStyles }) => {
  const { articleId } = useParams();
  const [data, setData] = useState(null);
  const [descriptifData, setDescriptifData] = useState(null);
  const [mediaData, setMediaData] = useState(null);
  const [sectionArticles, setSectionArticles] = useState([]);
  const [sectionId, setSectionId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isFixed, setIsFixed] = useState(false);
  const [isStopped, setIsStopped] = useState(false);

  const formRef = useRef(null);
  const articleCartesRef = useRef(null);
  const faqRef = useRef(null);
  const bandeauRef = useRef(null);

  //fetch fonction
  const fetchData = async (url) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      });
      return response.data.data;
    } catch (error) {
      setError("Une erreur est survenue lors du chargement des données.");
      console.error("Erreur lors de la récupération des données:", error);
      return null;
    }
  };

  //fetch data
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const articleData = await fetchData(
          `${API_BASE_URL}/articles/${articleId}?populate=*`
        );
        if (articleData) {
          setData(articleData);
          setSectionId(articleData.attributes.sectionId);
        }

        const descriptifData = await fetchData(
          `${API_BASE_URL}/template-sous-section-2-descriptifs?filters[articleId][$eq]=${articleId}&populate=*`
        );
        setDescriptifData(descriptifData?.[0]?.attributes || null);

        const mediaData = await fetchData(
          `${API_BASE_URL}/template-sous-section-1-media-bandeaus?filters[articleId][$eq]=${articleId}&populate=*`
        );
        setMediaData(mediaData?.[0] || null);

        if (sectionId) {
          const sectionArticlesData = await fetchData(
            `${API_BASE_URL}/articles?filters[sectionId][$eq]=${sectionId}&populate=*`
          );
          const filteredArticles =
            sectionArticlesData?.filter(
              (article) => article.id !== parseInt(articleId)
            ) || [];
          setSectionArticles(filteredArticles);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, [articleId, sectionId]);

  //Scroll Form fixed animation
  useEffect(() => {
    const handleScroll = () => {
      const form = formRef.current;
      const articleCartes = articleCartesRef.current;
      const bandeau = bandeauRef.current;
      const header = document.querySelector(".header");
      const headerHeight = header ? header.offsetHeight : 100;

      if (form && articleCartes && bandeau) {
        const formTop = form.getBoundingClientRect().top + window.scrollY;
        const articleTop =
          articleCartes.getBoundingClientRect().top + (window.scrollY - 54);
        const bandeauBottom =
          bandeau.getBoundingClientRect().bottom + window.scrollY;
        const formHeight = form.offsetHeight;
        const adjustedTop = formTop - (headerHeight + 25);

        if (window.scrollY >= adjustedTop && bandeauBottom < formTop) {
          setIsFixed(true);
        } else {
          setIsFixed(false);
        }

        if (window.scrollY + headerHeight + formHeight >= articleTop) {
          setIsStopped(true);
        } else {
          setIsStopped(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //fade in animation
  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1.5 } },
  };

  if (loading) return <div></div>;
  if (error)
    return (
      <div>
        <Erreur />
      </div>
    );
  if (!data || !mediaData || !descriptifData) {
    return (
      <div>
        <Erreur />
      </div>
    );
  }

  const bandeauData = {
    Titre: mediaData.attributes.Titre || "",
    Introduction: mediaData.attributes.Introduction || "",
    Media: {
      data: {
        attributes: {
          url: mediaData.attributes.Media.data[0]?.attributes.url || "",
          ext: mediaData.attributes.Media.data[0]?.attributes.ext || "",
        },
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>
          {mediaData?.attributes?.Titre || "Titre de la sous-section"}
        </title>
        <meta
          name="description"
          content={
            mediaData?.attributes?.Introduction ||
            "Description de la sous-section"
          }
        />
        <meta
          property="og:title"
          content={mediaData?.attributes?.Titre || "Titre de la sous-section"}
        />
        <meta
          property="og:description"
          content={
            mediaData?.attributes?.Introduction ||
            "Description de la sous-section"
          }
        />
        <meta
          property="og:image"
          content={
            mediaData?.attributes?.Media?.data?.[0]?.attributes?.url ||
            `${window.location.origin}/logo.webp`
          }
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <Header />
      <div className="template-sous-section">
        <div className="template-sous-section__bandeau" ref={bandeauRef}>
          <BandeauMedia data={bandeauData} hideTitle={true} />
          <motion.div
            className="template-sous-section__overlay"
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
          >
            <h2 className="template-sous-section__title">
              {bandeauData.Titre}
            </h2>
            <p className="template-sous-section__introduction">
              {bandeauData.Introduction}
            </p>
          </motion.div>
          <BoutonsDevisRdvWhatsapp
            customStyles={customStyles?.floatingButtons}
          />
        </div>
        <div className="template-sous-section__content">
          <div className="template-sous-section__left">
            <SousSectionDescriptifs data={descriptifData} />
          </div>
          <div className="template-sous-section__form-container">
            <div
              ref={formRef}
              className={`form-wrapper ${isFixed ? "form-fixed" : ""} ${
                isStopped ? "form-stopped" : ""
              }`}
            >
              <Form />
            </div>
          </div>
        </div>
        <div className="section-articles" ref={articleCartesRef}>
          <h4>NOS AUTRES ARTICLES DE LA CATEGORIE</h4>
          <SousSectionsCartes articles={sectionArticles} />
        </div>
        <section className="faq-section">
          <div className="faq-item-wrapper" ref={faqRef}>
            <FAQItem sectionId={sectionId} hideAside={true} />
          </div>
        </section>
        <JPSFilms />
        <CarrouselBlog sectionId={sectionId} />
        <QuestionProjet />
        <Footer />
      </div>
    </>
  );
};

export default TemplateSousSection;
