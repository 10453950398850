import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Header from "../components/commun/Header";
import ArticleBlogDescriptif from "../components/blog/ArticleBlogDescriptif";
import QuestionProjet from "../components/commun/QuestionProjet";
import Footer from "../components/commun/Footer";
import JPSMetaview from "../components/commun/JPSMetaview";
import BoutonsDevisRdvWhatsapp from "../components/commun/BoutonsDevisRdvWhatsapp";
import Erreur from "../components/commun/Erreur";

//Article by id page

const API_BASE_URL = "https://passionate-book-96c97196c7.strapiapp.com/api";

const ArticleBlogPage = ({ customStyles }) => {
  const { articleId } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isFixed, setIsFixed] = useState(false);
  const [isStopped, setIsStopped] = useState(false);
  const bandeauRef = useRef(null);
  const jpsMetaviewRef = useRef(null);
  const questionProjetRef = useRef(null);

  //fetch fonction
  const fetchData = async (url) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      });
      return response.data.data;
    } catch (error) {
      setError("Une erreur est survenue lors du chargement des données.");
      console.error("Erreur lors de la récupération des données:", error);
      return null;
    }
  };

  //fetch data
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const allData = await fetchData(
          `${API_BASE_URL}/nos-articles?populate=*&pagination[pageSize]=1000`
        );
        const article = allData.find(
          (article) => article.id === parseInt(articleId, 10)
        );
        setData(article.attributes);
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, [articleId]);

  //Scroll JPSMetaView fixed animation
  useEffect(() => {
    const handleScroll = () => {
      const jpsMetaview = jpsMetaviewRef.current;
      const questionProjet = questionProjetRef.current;
      const bandeau = bandeauRef.current;
      const header = document.querySelector(".header");
      const headerHeight = header ? header.offsetHeight : 100;

      if (jpsMetaview && questionProjet && bandeau) {
        const jpsMetaviewTop =
          jpsMetaview.getBoundingClientRect().top + window.scrollY;
        const questionProjetTop =
          questionProjet.getBoundingClientRect().top + (window.scrollY - 54);
        const bandeauBottom =
          bandeau.getBoundingClientRect().bottom + window.scrollY;
        const jpsMetaviewHeight = jpsMetaview.offsetHeight;
        const adjustedTop = jpsMetaviewTop - (headerHeight + 25);

        if (window.scrollY >= adjustedTop && bandeauBottom < jpsMetaviewTop) {
          setIsFixed(true);
        } else {
          setIsFixed(false);
        }

        if (
          window.scrollY + headerHeight + jpsMetaviewHeight >=
          questionProjetTop
        ) {
          setIsStopped(true);
        } else {
          setIsStopped(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (loading) return <div></div>;
  if (error)
    return (
      <div>
        <Erreur />
      </div>
    );

  return (
    <div className="article-blog-page">
      {/* SEO */}
      <Helmet>
        <title>{data?.Titre || "Notre actualité"}</title>
        <meta
          name="description"
          content={data?.Titre || "Découvrez notre actualité"}
        />
        <meta property="og:title" content={data?.Titre || "Notre actualité"} />
        <meta
          property="og:description"
          content={data?.Titre || "Découvrez notre actualité"}
        />
        <meta
          property="og:image"
          content={
            data?.MediaBandeau?.data?.attributes?.url ||
            `${window.location.origin}/logo.webp`
          }
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <Header />
      <div className="article-blog-page-container">
        <div className="article-blog-page__content">
          <main className="article-blog-page__main">
            <ArticleBlogDescriptif data={data} ref={bandeauRef} />
            <Link to="/nos-articles" className="return__button">
              Retour aux actualités
            </Link>
          </main>
          <aside className="article-blog-page__aside">
            <div
              ref={jpsMetaviewRef}
              className={`jps-metaview-container ${
                isFixed ? "jps-metaview-fixed" : ""
              } ${isStopped ? "jps-metaview-stopped" : ""}`}
            >
              <JPSMetaview />
            </div>
          </aside>
        </div>
        <div ref={questionProjetRef}>
          <QuestionProjet />
        </div>
        <Footer />
        <BoutonsDevisRdvWhatsapp customStyles={customStyles?.floatingButtons} />
      </div>
    </div>
  );
};

export default ArticleBlogPage;
