import React from "react";
import { useNavigate } from "react-router-dom";
import AsideSimulateur from "../../components/simulateur/AsideSimulateur";
import { Helmet } from "react-helmet";

const SimulateurSolaireQuatre = () => {
  const navigate = useNavigate();

  const handleRectangleClick = () => {
    navigate("/simulateur/solaire-cinq");
  };

  return (
    <div className="simulateur">
      <Helmet>
        <title>Notre simulateur étape 4 solaire</title>
        <meta
          name="description"
          content={"Parlons un peu de votre projet étape 4 solaire"}
        />
        <meta
          property="og:title"
          content={"Notre simulateur étape 4 solaire"}
        />
        <meta
          property="og:description"
          content={"Parlons un peu de votre projet étape 4 solaire"}
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <AsideSimulateur page={4} />
      <div className="simulateur-content">
        <main className="main">
          <h1>Quelle est l’exposition au soleil ?</h1>
          <div className="rectangle-grid smaller-rectangles">
            <div className="rectangle" onClick={handleRectangleClick}>
              Forte
            </div>
            <div className="rectangle" onClick={handleRectangleClick}>
              Moyenne
            </div>
            <div className="rectangle" onClick={handleRectangleClick}>
              Faible
            </div>
            <div className="rectangle" onClick={handleRectangleClick}>
              N/A
            </div>
          </div>
          <div className="image-container">
            <img
              src="/assets/images/Simulateur/Solaire4.png"
              alt="Exposition au soleil"
            />
          </div>
        </main>
      </div>
    </div>
  );
};

export default SimulateurSolaireQuatre;
