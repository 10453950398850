import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import NavBarMobile from "./NavBarMobile";
import NavBar from "./NavBar";

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  //mobile only menu
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  return (
    <header className="header">
      <div className="headerSection">
        <div className="header-logo">
          <Link to="/">
            <img
              className="HeaderLogoPicture"
              src="/Logo bleu horizontal.webp"
              alt="logo-JPS"
            />
          </Link>
        </div>

        {/* mobile only menu with NavBarMobile */}
        {isMobile ? (
          <>
            <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
              <span className="menu-toggle-icon">
                {isMobileMenuOpen ? "✕" : "⋮⋮⋮"}
              </span>
            </button>
            {isMobileMenuOpen && (
              <NavBarMobile onClose={() => setIsMobileMenuOpen(false)} />
            )}
          </>
        ) : (
          <div>
            <ul className="headerList">
              <li>
                <Link to="/nos-services">NOS SERVICES</Link>
              </li>
              <li>
                <Link to="/nos-realisations">NOS RÉALISATIONS</Link>
              </li>
              <li>
                <Link to="/nos-articles">ACTUALITÉS</Link>
              </li>
              <li>|</li>
              <li>
                <Link to="/contact">
                  <span>Contactez-nous</span>
                </Link>{" "}
                <br /> ou appelez le 04 37 54 66 33
              </li>
            </ul>
          </div>
        )}
      </div>

      {/* Desktop NavBar */}
      {!isMobile && <NavBar />}
    </header>
  );
};

export default Header;
