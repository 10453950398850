import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Accueil from "./pages/Accueil";
import Contact from "./pages/Contact";
import FAQ from "./pages/FAQ";
import GuidesReglementations from "./pages/GuidesReglementations";
import NosRealisations from "./pages/NosRealisations";
import TemplateSection from "./pages/TemplateSection";
import TemplateSousSection from "./pages/TemplateSousSection";
import NosArticles from "./pages/NosArticles";
import ArticleBlogPage from "./pages/ArticleBlogPage";
import QuiSommesNous from "./pages/QuiSommesNous";
import NosServices from "./pages/NosServices";
import SimulateurUn from "./pages/simulateur/SimulateurUn";
import SimulateurDeux from "./pages/simulateur/SimulateurDeux";
import SimulateurTrois from "./pages/simulateur/SimulateurTrois";
import SimulateurSolaireQuatre from "./pages/simulateur/SimulateurSolaireQuatre";
import SimulateurSecuriteQuatre from "./pages/simulateur/SimulateurSecuriteQuatre";
import SimulateurOccultantQuatre from "./pages/simulateur/SimulateurOccultantQuatre";
import SimulateurSolaireCinq from "./pages/simulateur/SimulateurSolaireCinq";
import SimulateurFinal from "./pages/simulateur/SimulateurFinal";
import SimulateurSecuriteCinq from "./pages/simulateur/SimulateurSecuriteCinq";
import PlanDuSite from "./pages/PlanDuSite";
import MentionsLegales from "./pages/MentionsLegales";
import PolitiqueDeConfi from "./pages/PolitiqueDeConfi";
import Erreur from "./components/commun/Erreur";
import CookiesBandeau from "./components/CookiesBandeau";
import AnalyticsTracker from "./components/AnalyticsTracker";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

if (process.env.NODE_ENV !== "production") {
  window["ga-disable-G-EZMEV7T3VX"] = true;
}

const App = () => {
  return (
    <BrowserRouter>
      <AnalyticsTracker />
      <ScrollToTop />
      <CookiesBandeau />
      <Routes>
        <Route path="/" element={<Accueil />} />
        <Route path="/erreur" element={<Erreur />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faq" element={<FAQ />} />
        <Route
          path="/guides-reglementations"
          element={<GuidesReglementations />}
        />
        <Route path="/nos-realisations" element={<NosRealisations />} />
        <Route path="/nos-articles" element={<NosArticles />} />
        <Route path="/article/:articleId" element={<ArticleBlogPage />} />
        <Route path="/qui-sommes-nous" element={<QuiSommesNous />} />
        <Route path="/nos-services" element={<NosServices />} />
        <Route path="/section/:id" element={<TemplateSection />} />
        <Route
          path="/template-sous-section/:articleId"
          element={<TemplateSousSection />}
        />
        <Route path="/simulateur" element={<SimulateurUn />} />
        <Route path="/simulateur/deux" element={<SimulateurDeux />} />
        <Route path="/simulateur/trois" element={<SimulateurTrois />} />
        <Route
          path="/simulateur/solaire-quatre"
          element={<SimulateurSolaireQuatre />}
        />
        <Route
          path="/simulateur/solaire-cinq"
          element={<SimulateurSolaireCinq />}
        />
        <Route
          path="/simulateur/securite-quatre"
          element={<SimulateurSecuriteQuatre />}
        />
        <Route
          path="/simulateur/securite-cinq"
          element={<SimulateurSecuriteCinq />}
        />
        <Route
          path="/simulateur/occultant-quatre"
          element={<SimulateurOccultantQuatre />}
        />
        <Route path="/simulateur/final" element={<SimulateurFinal />} />
        <Route path="/plan-du-site" element={<PlanDuSite />} />
        <Route path="/mentions-legales" element={<MentionsLegales />} />
        <Route
          path="/politique-de-confidentialite"
          element={<PolitiqueDeConfi />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
