import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../components/commun/Header";
import Erreur from "../components/commun/Erreur";
import Footer from "../components/commun/Footer";
import BoutonsDevisRdvWhatsapp from "../components/commun/BoutonsDevisRdvWhatsapp";
import ArticleBlogDescriptif from "../components/blog/ArticleBlogDescriptif";
import { Helmet } from "react-helmet";

const API_BASE_URL = "https://passionate-book-96c97196c7.strapiapp.com/api";

const SitemapPage = ({ customStyles }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  //fetch fonction
  const fetchData = async (url) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      });
      return response.data.data;
    } catch (error) {
      setError("Une erreur est survenue lors du chargement des données.");
      console.error("Erreur lors de la récupération des données:", error);
      return null;
    }
  };

  //fetch data
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const sitePlanData = await fetchData(
          `${API_BASE_URL}/plan-de-sites?populate=*`
        );
        if (sitePlanData && sitePlanData.length > 0) {
          setData(sitePlanData[0].attributes);
        } else {
          setData(null);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, []);

  if (loading) return <div></div>;
  if (error)
    return (
      <div>
        <Erreur />
      </div>
    );

  return (
    <div className="sitemap-page">
      <Helmet>
        <title>Plan du site</title>
        <meta name="description" content={"Découvrez notre plan du site"} />
        <meta property="og:title" content={"Plan du site"} />
        <meta
          property="og:description"
          content={"Découvrez notre plan du site"}
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <Header />
      <h1
        style={{
          marginTop: "13vh",
          marginLeft: "8vw",
        }}
      >
        Plan du site
      </h1>
      <div className="sitemap-page-container" style={{ marginLeft: "1%" }}>
        {data ? <ArticleBlogDescriptif data={data} /> : <Erreur />}
      </div>
      <Footer />
      <BoutonsDevisRdvWhatsapp customStyles={customStyles?.floatingButtons} />
    </div>
  );
};

export default SitemapPage;
